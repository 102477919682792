import axios from 'axios'
export default {
    watch: {

    },
    methods: {
        getParentCatProducts(id,order='desc',limit='0',start='0'){
            const cats = this.getChildrenCategoryById(id).map((c)=>{
                return c.id;
            });
            id = id.toString();
            cats.push(id);
            limit = parseInt(limit);
            start = parseInt(start);
            let pids = [];
            for(let i = 0; i < cats.length; i++){
                let p = this.catProducts(cats[i]);
                for(let j = 0; j < p.length; j++){
                    if(!pids.includes(p[j].id)){
                        pids.push(p[j].id);
                    }
                }
            }
            if(order === 'asc'){
                pids.sort((a,b)=>{
                    return a-b;
                });
            }else{
                pids.sort((a,b)=>{
                    return b-a;
                });
            }
            if(limit > 0){
                pids = pids.slice(start,(start+limit));
            }else{
                pids = pids.slice(start);
            }
            return pids.map((p) => {
                return this.getProductById(p);
            });
        },
        catProducts(cat,order='desc',limit='0',start='0'){
            const products = this.$store.state.products;
            const p = [];
            limit = parseInt(limit);
            start = parseInt(start);
            for(let i = 0; i < products.length; i++){
                for(let j = 0; j < products[i].category.length; j++){
                    if((products[i].category[j].toString() === cat.toString())){
                        p.push(products[i]);
                    }
                }
            }
            if(order !== 'asc'){
                p.reverse();
            }
            if(limit > 0){
                return p.slice(start,(start+limit));
            }else{
                return p.slice(start);
            }
        },
        getProductById(id){
            const products = this.$store.state.products;
            let p = null;
            for(let i = 0; i < products.length; i++){
                if(products[i].id.toString() === id.toString()){
                    p = products[i];
                    break;
                }
            }
            return p;
        },
        getCategoryById(id){
            const category = this.$store.state.category;
            let p = null;
            for(let i = 0; i < category.length; i++){
                if(category[i].id.toString() === id.toString() || category[i].slug === id.toString()){
                    p = category[i];
                    break;
                }
            }
            return p;
        },
        getChildrenCategoryById(id){
            const category = this.$store.state.category;
            const c = [];
            for(let i = 0; i < category.length; i++){
                if(category[i].parent.toString() === id.toString()){
                    c.push(category[i]);
                }
            }
            return c;
        },
        addToCart(id, qty=1){
            let cart = this.$store.state.cart;
            qty = parseInt(qty);
            let d = -1;
            for(let i = 0; i < cart.length; i++){
                if(cart[i].id.toString() === id.toString()){
                    d = i;
                }
            }
            if(d >= 0){
                cart[d]['qty'] = +cart[d]['qty'] + qty;
            }else{
                cart.unshift({id: id, qty: qty});
            }
            this.setCart(cart);
            this.$store.commit('addedToCart',{status:true,item:this.getProductById(id).name});
            //console.log(session.get('cart'));
        },
        formatPrice(p){
            p = +p;
            let r,f,t,d;
            r = Number.isInteger(p) ? p : p.toFixed(2);
            r = r.toString();
            f = r.split('.');
            t = Number(f[0]);
            d = typeof f[1] !== 'undefined' ? '.'+f[1] : '';
            return t.toLocaleString('en-US') + d;
        },
        getCartPos(id){
            let s = -1;
            const cart = this.$store.state.cart;
            for(let i = 0; i < cart.length; i++){
                if(cart[i].id.toString() === id.toString()){
                    s = i;
                }
            }
            return s;
        },
        removeCartItem(id){
            const s = this.getCartPos(id);
            const cart = this.$store.state.cart;
            if(s >= 0){
                cart.splice(s, 1);
                this.setCart(cart);
            }
        },
        cartInputAction(id,action,qty=1){
            const s = this.getCartPos(id);
            const cart = this.$store.state.cart;
            if(s >= 0){
                if(action === 'add'){
                    cart[s].qty = +cart[s].qty + +qty;
                }
                if(action === 'minus'){
                    cart[s].qty = cart[s].qty < 2 ? 1 : +cart[s].qty - +qty;
                }
                if(action === 'update'){
                    cart[s].qty = qty <= 0 ? 1 : +qty;
                }
                this.setCart(cart);
            }
        },
        getCartTotalPrice(){
            let p = 0;
            const cart = this.$store.state.cart;
            for(let i = 0; i < cart.length; i++){
                let item = this.getProductById(cart[i].id);
                let pp = item.dis_price > 0 ? item.dis_price : item.reg_price;
                p += +pp * +cart[i].qty;
            }
            return p;
        },
        clearCart(){
            this.setCart([]);
        },
        setCart(cart){
            this.$session.set('cart',JSON.stringify(cart));
            this.$store.commit('setCart', cart);
        },
        validateCoupon(code){
            return new Promise(resolve => {
                axios.post(this.$store.state.base_url+'api',{
                        action: 'validate-coupon',
                        code: code,
                        sub: this.getCartTotalPrice()
                    }, {
                        headers: {
                            "Content-Type": "multipart/form-data"
                        }
                    }).then((res) => {
                        if(res.status !== 200){
                            resolve({
                                status: '201',
                                message: 'Error validating code'
                            });
                        }
                        resolve(res.data);
                }, (error) => {
                    resolve({
                        status: '201',
                        message: 'Error validating code'
                    });
                });
            });
        },
        getShippingById(id){
            const shipping = this.$store.state.shipping;
            let p = null;
            for(let i = 0; i < shipping.length; i++){
                if(shipping[i].id.toString() === id.toString()){
                    p = shipping[i];
                    break;
                }
            }
            return p;
        },
        setToast(text,color){
            this.toast.color = color;
            this.toast.show = true;
            this.toast.text = text;
        },
        formatDate(date){
            const d = new Date(date*1000);
            let n = d.getDate()+ "/"+(d.getMonth()+1)+ "/"+d.getFullYear()+" ";
            n += d.getHours() < 10 ? "0"+d.getHours() : d.getHours();
            n += ":";
            n += d.getMinutes() < 10 ? "0"+d.getMinutes() : d.getMinutes();
            return n;
        }
    },
    data(){
        return {
            cartCount: 0,
            toast: {
                color: '',
                show: false,
                text: ''
            },
        }
    }
}