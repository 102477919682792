<template>
    <v-container
    >
        <v-row class="py-5 py-md-16 px-3 px-md-0">
            <v-col cols="12"
                   class="pb-md-10 pll-6 pll-md-12"
                   v-if="!loading && html !== ''"
            >
                <h2>Privacy Policy</h2>
                <div class="mt-6 mt-sm-10" v-html="html"></div>
            </v-col>
            <v-col cols="12"
                   class="mt-10"
                   v-else-if="!loading && html === ''"
            >
                <slide-y-down-transition
                        appear
                        :delay="500">
                    <NotFound></NotFound>
                </slide-y-down-transition>
            </v-col>
            <v-col cols="12"
                   v-else
            >
                <v-skeleton-loader
                        type="article, paragraph, paragraph, paragraph, paragraph, paragraph, paragraph, actions"
                ></v-skeleton-loader>
            </v-col>
        </v-row>
        <v-snackbar
                v-model="toast.show"
                :multi-line="true"
                timeout="5000"
                right
                :color="toast.color"
        >
            {{ toast.text }}
        </v-snackbar>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import NotFound from '@/components/404';
    import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
    export default {
        name: "Privacy",
        data(){
            return {
                title: 'Privacy Policy',
                html: '',
                loading: true,
            }
        },
        created(){
            this.loadPolicy();
        },
        components: {
            SlideYUpTransition,
            SlideYDownTransition,
            NotFound
        },
        metaInfo(){
            return {
                title: this.title
            }
        },
        methods: {
            loadPolicy(){
                axios.get(this.$store.state.base_url+'api?action=load-privacy-policy')
                    .then((res) => {
                        this.loading = false;
                        if(res.status !== 200){
                            this.title = 'Page Not Found';
                            this.setToast('Error loading page','red');
                        }

                        if(res.data.status === '200'){
                            this.html = res.data.body;
                        }
                    }, (error) => {
                        this.loading = false;
                        this.title = 'Page Not Found';
                        this.setToast('Error loading page','red');
                    });
            },
        }
    }
</script>

<style scoped>

</style>