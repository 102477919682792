<template>
    <v-container>
        <v-row class="py-5 py-md-16 px-3 px-md-0">
            <v-col cols="12"
                   class="pb-md-10 pll-6 pll-md-12"
            >
                <div
                        v-if="invoice !== null"
                >
                    <h2>Invoice</h2>
                    <v-breadcrumbs class="pa-0 ma-0 mt-2 mt-md-3 mb-10" :items="breadcrumb">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
                <div v-if="!loaded_invoice">
                    <v-skeleton-loader
                            type="table-heading, list-item-two-line, image, list-item-two-line, table-tfoot"
                            max-height="2000"
                    ></v-skeleton-loader>
                </div>
                <div
                        class="mt-10"
                        v-if="loaded_invoice && invoice === null">
                    <slide-y-down-transition
                            appear
                            :delay="500">
                        <NotFound></NotFound>
                    </slide-y-down-transition>
                </div>
                <div v-if="loaded_invoice && invoice !== null">
                    <v-card
                        tile
                        class="pa-6 pa-sm-10 pa-md-10 invoice-contact"
                    >
                        <v-row>
                            <v-col cols="12" sm="6">
                                <v-img
                                        alt="Vuetify Logo"
                                        class="shrink mr-2 mb-3"
                                        contain
                                        :src="$vuetify.theme.dark?require('@/assets/exclusive-fingers-logo-new-white.png'):require('@/assets/exclusive-fingers-logo-new.png')"
                                        width="200"
                                />
                                <v-btn
                                        text
                                        x-small
                                        class="soc-btn"
                                >
                                    <v-icon left>
                                        mdi-phone
                                    </v-icon>
                                    (224) 388-1978
                                </v-btn>
                                <v-btn
                                        text
                                        x-small
                                        class="soc-btn"
                                >
                                    <v-icon left>
                                        mdi-gmail
                                    </v-icon>
                                    exclusivefingers@gmail.com
                                </v-btn>
                            </v-col>
                            <v-col cols="12" sm="6" class="text-sm-end">
                                <h3>Shipping Details</h3>
                                <div class="text">{{ invoice.name }}</div>
                                <div class="text">{{ invoice.phone }}</div>
                                <div class="text">{{ invoice.email }}</div>
                                <div class="text">{{ invoice.address }}</div>
                            </v-col>
                            <v-col cols="12">
                                <v-divider></v-divider>
                                <v-row class="py-5">
                                    <v-col cols="12" sm="4" class="my-0 py-0 py-sm-2">
                                        <div class="text"><span class="mr-2" style="font-weight: 600;">Invoice no.</span>{{ invoice.ref }}</div>
                                    </v-col>
                                    <v-col cols="12" sm="4" class="my-0 py-0 py-sm-2">
                                        <div class="text"><span class="mr-2" style="font-weight: 600;">Issued</span>{{ formatDate(+invoice.time) }}</div>
                                    </v-col>
                                    <v-col cols="12" sm="4" class="text-sm-end my-0 py-0 py-sm-2"
                                           v-if="invoice.status === '0'"
                                    >
                                        <div class="text">
                                            <span class="mr-2"
                                                  style="font-weight: 600;"
                                            >Expires</span>
                                            {{ formatDate(+invoice.time + (3600 * +expire)) }}
                                        </div>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-simple-table class="my-5">
                                    <template v-slot:default>
                                        <thead>
                                        <tr>
                                            <th class="text-left">
                                                #
                                            </th>
                                            <th class="text-left">
                                                Description
                                            </th>
                                            <th class="text-left">
                                                Price
                                            </th>
                                            <th class="text-left">
                                                Qty
                                            </th>
                                            <th class="text-left">
                                                Total
                                            </th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                            <tr
                                                    v-for="(item,n) in invoice.items"
                                                    :key="item.id"
                                            >
                                                <td>{{ n + 1 }}</td>
                                                <td>{{ item.name }}</td>
                                                <td>${{ formatPrice(item.price) }}</td>
                                                <td>{{ item.qty }}</td>
                                                <td>${{ formatPrice(+item.price * +item.qty) }}</td>
                                            </tr>
                                        </tbody>
                                    </template>
                                </v-simple-table>
                                <v-row class="mb-2">
                                    <v-col class="pl-6 text-end" cols="12">
                                        <h4 class="foot"><span class="mr-5">Subtotal:</span> ${{ formatPrice(invoice.subtotal) }}</h4>
                                        <h4 class="foot" v-if="invoice.coupon_dis > 0"><span class="mr-5">Discount ({{ invoice.coupon }}):</span> -${{ formatPrice(invoice.coupon_dis) }}</h4>
                                        <h4 class="foot"><span class="mr-5">Shipping Fee:</span> ${{ formatPrice(invoice.ship_fee) }}</h4>
                                        <h4 class="foot"><span class="mr-5">Total:</span> ${{ formatPrice(invoice.total) }}</h4>
                                    </v-col>
                                </v-row>
                                <v-divider></v-divider>
                                <v-row class="mt-2 mb-4">
                                    <v-col cols="12" v-if="invoice.status === '3'">
                                        <h3>Order Status: Failed</h3>
                                        <div class="text">Reason: {{ invoice.reason }}</div>
                                    </v-col>
                                    <v-col cols="12" v-else-if="invoice.status === '2'">
                                        <h3>Order Status: Delivered</h3>
                                    </v-col>
                                    <v-col cols="12" v-else-if="invoice.status === '1'">
                                        <h3>Order Status: Processing</h3>
                                    </v-col>
                                    <v-col cols="12" v-else>
                                        <h3>Order Status: Pending</h3>
                                        <v-row class="mt-2">
                                            <v-col cols="12" sm="6">
                                                <v-card
                                                        elevation="6"
                                                        class="pa-5 pt-0"
                                                >
                                                    <v-card-title class="pa-4">
                                                        Pay with CashApp
                                                    </v-card-title>
                                                    <v-divider></v-divider>
                                                    <v-card-text>You can make the payment of <span style="font-weight: 600;">${{ formatPrice(invoice.total) }}</span> to our cashapp account.</v-card-text>
                                                    <div class="text-center">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                        outlined
                                                                        color="primary"
                                                                        v-clipboard:copy="cashapp"
                                                                        v-clipboard:success="onCopySuccess"
                                                                        v-clipboard:error="onCopyError"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                >
                                                                    <v-icon left>
                                                                        mdi-scissors-cutting
                                                                    </v-icon>
                                                                    {{ cashapp }}
                                                                </v-btn>
                                                            </template>
                                                            <span>Click to copy</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <v-card-text>Use the Invoice No. <span style="font-weight: 600;">{{ invoice.ref }}</span> as the reference in order to verify your payment.</v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" sm="6">
                                                <v-card
                                                        elevation="6"
                                                        class="pa-5 pt-0"
                                                >
                                                    <v-card-title class="pa-4">
                                                        Pay with Zelle
                                                    </v-card-title>
                                                    <v-divider></v-divider>
                                                    <v-card-text>Yes! We also accept Zelle. You can make the payment of <span style="font-weight: 600;">${{ formatPrice(invoice.total) }}</span> to our zelle account.</v-card-text>
                                                    <div class="text-center">
                                                        <v-tooltip top>
                                                            <template v-slot:activator="{ on, attrs }">
                                                                <v-btn
                                                                        outlined
                                                                        color="primary"
                                                                        v-clipboard:copy="zelle"
                                                                        v-clipboard:success="onCopySuccess"
                                                                        v-clipboard:error="onCopyError"
                                                                        v-bind="attrs"
                                                                        v-on="on"
                                                                >
                                                                    <v-icon left>
                                                                        mdi-scissors-cutting
                                                                    </v-icon>
                                                                    {{ zelle }}
                                                                </v-btn>
                                                            </template>
                                                            <span>Click to copy</span>
                                                        </v-tooltip>
                                                    </div>
                                                    <v-card-text>Use the Invoice No. <span style="font-weight: 600;">{{ invoice.ref }}</span> as the reference in order to verify your payment.

                                                    </v-card-text>
                                                </v-card>
                                            </v-col>
                                            <v-col cols="12" class="text">
                                                <div>You will receive a mail once the payment has been confirmed (this might take a while).
                                                    If you don't get a confirmation mail within 1-6 hours, please go to contact us page to get in touch.</div>
                                            </v-col>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <div class="text-end" id="print">
                                    <v-btn
                                            color="primary"
                                            outlined
                                            @click="printInvoice"
                                    >
                                        Print
                                    </v-btn>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </div>
            </v-col>
        </v-row>
        <v-snackbar
                v-model="toast.show"
                :multi-line="true"
                timeout="5000"
                right
                :color="toast.color"
        >
            {{ toast.text }}
        </v-snackbar>
    </v-container>
</template>

<script>
    import axios from 'axios';
    import NotFound from '@/components/404';
    import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
    export default {
        name: "Invoice",
        data(){
            return {
                breadcrumb: [

                ],
                title: 'Invoice',
                invoice: null,
                loaded_invoice: false,
                id: '',
                expire: 24,
                cashapp: '$ajibodu',
                zelle: '2243881978'
            }
        },
        created(){
            this.id = this.$route.params.id;
            this.loadInvoice();
        },
        metaInfo(){
            return {
                title: this.title
            }
        },
        components: {
            SlideYUpTransition,
            SlideYDownTransition,
            NotFound
        },
        methods: {
            loadInvoice(){
                axios.get(this.$store.state.base_url+'api?action=fetch-invoice&id='+this.id)
                    .then((res) => {
                        this.loaded_invoice = true;
                        if(res.status !== 200){
                            this.title = 'Page Not Found';
                            this.setToast('Error loading order','red');
                        }

                        if(res.data.status === '200'){
                            this.invoice = res.data.invoice;
                            this.expire = res.data.expire;
                            this.breadcrumb.push({
                                text: 'Ref #'+this.id,
                                disabled: true,
                            });
                            this.title = 'Invoice #'+this.invoice.ref;
                        }
                }, (error) => {
                    this.loaded_invoice = true;
                    this.title = 'Page Not Found';
                    this.setToast('Error loading invoice','red');
                });
            },
            onCopyError(){
                this.setToast('An error occurred while copying!','primary');
            },
            onCopySuccess(){
                this.setToast('Copied Successfully!','primary');
            },
            printInvoice(){
                const head = document.querySelector('header');
                const foot = document.querySelector('footer');
                const p = document.getElementById('print');
                const d = this.$vuetify.theme.dark;
                if(d){
                    this.$vuetify.theme.dark = false;
                }
                setTimeout(()=>{
                    head.classList.add('d-none');
                    foot.classList.add('d-none');
                    p.classList.add('d-none');
                    window.print();
                },1000);
                window.onafterprint = ()=>{
                    if(d){
                        this.$vuetify.theme.dark = true;
                    }
                    setTimeout(()=>{
                        head.classList.remove('d-none');
                        foot.classList.remove('d-none');
                        p.classList.remove('d-none');
                    },1000);
                };
                /*const b = document.createDocumentFragment();
                const h = document.createElement('html');
                h.appendChild(document.createTextNode(document.querySelector('html').innerHTML));
                b.appendChild(h);
                //b.removeChild(b.querySelector('header'));
                //b.removeChild(b.querySelector('footer'));
                console.log(b);
                 */
            }
        }
    }
</script>

<style>
    .invoice-contact .soc-btn{
        text-transform: lowercase;
        font-weight: 400;
    }
    .invoice-contact .text{
        font-size: 13px;
        padding-top: 5px;
    }
    .invoice-contact .foot{
        font-weight: 600;
        padding-bottom: 7px;
    }
</style>