import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    title: 'Holla',
    theme: 'light',
    base_url: 'https://exclusivefingers.com/',
    cartCount: 0,
    cart: [],
    addedtocart: false,
    addedtocartitem: '',
    products: [],
    category: [],
    banners: [],
    shipping: [],
    loaded_data: false,
    featured_cat: [],
    social: [
      {
        name: 'phone',
        link: 'tel:+1(224)388-1978'
      },
      {
        name: 'gmail',
        link: 'mailto:exclusivefingers@gmail.com'
      },
      {
        name: 'instagram',
        link: 'https://instagram.com/exclsuive_fingers'
      },
    ]
  },
  getters: {

  },
  mutations: {
    setTheme: (state, payload) => {
      state.theme = payload === 'dark'?'dark':'light';
    },
    loadData: (state, payload) => {
      if(typeof payload.products !== 'undefined'){
        state.products = payload.products;
      }
      if(typeof payload.category !== 'undefined'){
        state.category = payload.category;
      }
      if(typeof payload.banners !== 'undefined'){
        state.banners = payload.banners;
      }
      if(typeof payload.shipping !== 'undefined'){
        state.shipping = payload.shipping;
      }
      if(typeof payload.featured_cat !== 'undefined'){
        state.featured_cat = payload.featured_cat;
      }
      if(!state.loaded_data){
        state.loaded_data = true;
      }
    },
    setProducts: (state, payload) => {
      state.products = payload;
    },
    setCategory: (state, payload) => {
      state.category = payload;
    },
    setBanners: (state, payload) => {
      state.banners = payload;
    },
    setShipping: (state, payload) => {
      state.shipping = payload;
    },
    setCart: (state, payload) => {
      state.cart = payload;
    },
    addedToCart: (state, payload) => {
      state.addedtocart = payload.status;
      state.addedtocartitem = payload.item;
    }
  },
  actions: {
    addedToCart: ({ commit }, payload) => {
      commit('addedToCart',{status: payload.status, item: payload.item});
    },
      loadData: ({commit, state}) => {
        fetch(state.base_url+'api?action=load-vue-data')
            .then(res => {
              if(res.status !== 200){
                console.log('Failed to load data');
              }
              return res.json();
            })
            .then(data => {
              if(data.status !== '200'){
                return console.log('Database Error');
              }
              commit('loadData',{
                products: data.product,
                category: data.category,
                banners: data.banner,
                shipping: data.shipping,
                featured_cat: data.featured_cat
              });
            })
            .catch(err => {
              console.log('Error loading data');
            });
      }
  },
  modules: {
  }
})
