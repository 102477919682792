require('es6-promise').polyfill();

import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import VueMeta from 'vue-meta'
import VueSession from 'vue-session'
import mixin from './mixins'
import VueCarousel from 'vue-carousel';
import VueClipboard from 'vue-clipboard2'

import router from './router'
import store from './store'
require('vue2-animate/dist/vue2-animate.min.css');
require('./css/main.css');


Vue.use(VueClipboard);
Vue.use(VueSession, {
  persist: true
});
Vue.use(VueMeta,{
  refreshOnceOnNavigation: true
});
Vue.mixin(mixin);
//Vue.use(VueCarousel);

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app');
