<template>
  <v-container>
    <v-row class="py-5 py-md-16">
      <v-col cols="12" md="6"
             class="align-center"
      >
        <v-img
          :src="require('@/assets/about-us.svg')"
          width="100%"
          transition="scale-transition"
        >

        </v-img>
      </v-col>
      <v-col cols="12" md="6" class="px-5 px-sm-8 px-md-0">
        <h1 class="head mb-4">About Exclusive Fingers</h1>
        <p>A spearheading vision and a tireless responsibility to quality, have made Exclusive Fingers a bonafide maker of premium sustenance services. Our main goal is to meet the qualities and requests of clients through a cost effective procedure.</p>
        <p>Exclusive Fingers‘ kitchen development has been enlivened with the energy to cook and serve solid, African delicacies. In contrast to other African kitchens, Exclusive Fingers was made with the explicit expectation to give you a taste of home.</p>
        <p>Our group takes pride in the way that we can furnish our new and faithful clients with extraordinary finger smacking African delicacies that is unusual at some other kitchens you visit. We welcome you to sit back while our best kitchen expert sets you up a scrumptious dinner utilizing the best and freshest ingredients.</p>
        <p>We also make lip gloss products specifically designed with your real beauty routine in mind. We’re building the longer term beauty company where everything we make starts with you.</p>
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
  export default{
    name: 'About',
    metaInfo(){
      return {
        title: 'About Us'
      }
    },

  }
</script>
<style scoped>
  p{
    font-weight: 300;
    text-align: justify;
    line-height: 30px;
  }
  @media (max-width: 767px){
    .head{
      font-size: 18px;
    }
    p{
      font-size: 13px;
      line-height: 20px;
    }
  }
</style>