<template>
    <v-container
    >
        <v-row class="py-5 py-md-16 px-3 px-md-0">
            <v-col cols="12" class="pb-md-10 pll-6 pll-md-12">
                <div v-if="category !== null">
                    <h2>{{ title }}</h2>
                    <v-breadcrumbs class="pa-0 ma-0 mt-2 mt-md-3 mb-10" :items="breadcrumb">
                        <template v-slot:divider>
                            <v-icon>mdi-chevron-right</v-icon>
                        </template>
                    </v-breadcrumbs>
                </div>
                <div v-if="!$store.state.loaded_data">
                    <v-row>
                        <v-col cols="12" sm="6" md="4" lg="2"
                               v-for="i in 12"
                               :key="i"
                        >
                            <v-skeleton-loader
                                    class="mx-auto"
                                    max-height="300"
                                    type="card"
                            ></v-skeleton-loader>
                        </v-col>
                    </v-row>
                </div>
                <div v-else>
                    <div v-if="category !== null">
                        <div v-if="children.length > 0" class="cat-tab">
                            <v-tabs
                                    v-model="tab"
                                    color="primary"
                                    background-color="transparent"
                                    grow
                            >
                                <v-tab key="all">
                                    All
                                </v-tab>
                                <v-tab
                                        v-for="item in children"
                                        :key="item.id"
                                >
                                    {{ item.name }}
                                </v-tab>
                            </v-tabs>
                            <v-tabs-items
                                    v-model="tab"
                                    style="background-color: transparent;"
                                    class="mt-10"
                            >
                                <v-tab-item key="all">
                                    <v-row>
                                        <v-col
                                                cols="12" sm="6" md="4" lg="2"
                                                v-for="(p, n) in getParentCatProducts(category.id)"
                                                :key="p.id"
                                        >
                                            <slide-y-down-transition
                                                    appear
                                                    :delay="400 + (n * 100)">
                                                <ProductItem
                                                        class="mx-3 my-2"
                                                        :item="p">

                                                </ProductItem>
                                            </slide-y-down-transition>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                                <v-tab-item
                                        v-for="item in children"
                                        :key="item.id"
                                >
                                    <v-row>
                                        <v-col
                                                cols="12" sm="6" md="4" lg="2"
                                                v-for="(p, n) in catProducts(item.id)"
                                                :key="p.id"
                                        >
                                            <slide-y-down-transition
                                                    appear
                                                    :delay="400 + (n * 100)">
                                                <ProductItem
                                                        class="mx-3 my-2"
                                                        :item="p"></ProductItem>
                                            </slide-y-down-transition>
                                        </v-col>
                                    </v-row>
                                </v-tab-item>
                            </v-tabs-items>
                        </div>
                        <div v-else>
                            <v-row>
                                <v-col
                                        cols="12" sm="6" md="4" lg="2"
                                        v-for="(p, n) in catProducts(category.id)"
                                        :key="p.id"
                                >
                                    <slide-y-down-transition
                                            appear
                                            :delay="400 + (n * 100)">
                                        <ProductItem :item="p"></ProductItem>
                                    </slide-y-down-transition>
                                </v-col>
                            </v-row>

                        </div>
                    </div>
                    <div v-else>
                        <v-row>
                            <v-col cols="12" class="text-center mt-4 mb-16 mt-md-10">
                                <slide-y-down-transition
                                        appear
                                        :delay="500">
                                    <NotFound></NotFound>
                                </slide-y-down-transition>
                            </v-col>
                        </v-row>
                    </div>

                </div>


            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import ProductItem from '@/components/ProductItem';
    import NotFound from '@/components/404';
    import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
    export default {
        name: "Category",
        created(){
            if(this.$store.state.loaded_data){
                this.insertData();
            }
        },
        watch: {
            '$store.state.loaded_data'(val){
                if(val){
                    this.insertData();
                }
            }
        },
        methods: {
            insertData(){
                this.category = this.getCategoryById(this.$route.params.id);
                if(this.category === null){
                    this.title = 'Page Not Found';
                    return;
                }
                this.title = this.category.name;
                let parent = null;
                if(this.category.parent != ''){
                    parent = this.getCategoryById(this.category.parent);
                    this.breadcrumb.push({
                        text: parent.name,
                        disabled: false,
                        to: '/store/category/'+parent.slug,
                    });
                }
                this.children = this.getChildrenCategoryById(this.category.id);
                this.breadcrumb.push({
                    text: this.category.name,
                    disabled: true,
                });
            }
        },
        data(){
            return {
                breadcrumb: [
                    {
                        text: 'Store',
                        disabled: false,
                        to: '/',
                    }
                ],
                title: 'Category',
                category: null,
                children: [],
                tab: null
            }
        },
        metaInfo(){
            return {
                title: this.title
            }
        },
        components: {
            ProductItem,
            SlideYUpTransition,
            SlideYDownTransition,
            NotFound
        }
    }
</script>

<style>
    .v-slide-group__prev--disabled{
        display: none !important;
        width: 0;
    }
</style>