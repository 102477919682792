<template>
    <v-container>
        <v-row class="py-5 py-md-16 px-3 px-md-0">
            <v-col cols="12" class="text-center mt-16 mb-16">
                <slide-y-down-transition
                        appear
                        :delay="500">
                    <NotFoundComp></NotFoundComp>
                </slide-y-down-transition>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import NotFoundComp from '@/components/404';
    import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
    export default {
        name: "NotFound",
        components: {
            NotFoundComp,
            SlideYUpTransition,
            SlideYDownTransition
        },
        metaInfo(){
            return {
                title: 'Page Not Found'
            }
        },
    }
</script>

<style scoped>

</style>