<template>
    <v-card
            class="mx-auto"
            :shaped="shaped"
            :max-width="maxWidth"
            :height="height"
    >
        <v-img
                height="150"
                :src="item.image"
        ></v-img>

        <v-card-title class="py-0 pt-2" style="font-size: 15px;">{{ item.name }}</v-card-title>
        <v-card-text
                color="primary"
        >
            <div class="text-end">
                <del class="mr-2"
                     style="font-weight: 400; font-size: 12px;"
                     v-if="parseFloat(item.dis_price) > 0"
                >
                    ${{ formatPrice(item.reg_price) }}
                </del>
                <span style="font-weight: 600; font-size: 15px;"
                      v-if="parseFloat(item.dis_price) > 0"
                >
                                            ${{ formatPrice(item.dis_price) }}
                                        </span>
                <span style="font-weight: 600; font-size: 15px;"
                      v-else
                >
                                            ${{ formatPrice(item.reg_price) }}
                                        </span>
            </div>
        </v-card-text>

        <v-divider class="mx-4"></v-divider>
        <v-card-actions
                class="justify-end"
        >
            <v-btn
                    color="primary lighten-2"
                    plain
                    @click="addToCart(item.id)"
            >
                Add to Cart
            </v-btn>
        </v-card-actions>
    </v-card>
</template>

<script>
    export default {
        name: "ProductItem",
        props: {
            item: {
                type: Object,
                default: ()=>{
                    return {

                    }
                }
            },
            shaped: {
                type: Boolean,
                default: true
            },
            maxWidth: {
                type: [String, Number],
                default: undefined
            },
            height: {
                type: [String, Number],
                default: undefined
            },
        },
        method: {

        }
    }
</script>

<style scoped>

</style>