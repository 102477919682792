<template>
    <v-container>
        <v-row class="py-5 py-md-16 px-3 px-md-0">
            <v-col cols="12" class="pb-md-10 pll-6 pll-md-12">
                <h2>Checkout</h2>
                <v-breadcrumbs class="pa-0 ma-0 mt-2 mt-md-3 mb-10" :items="breadcrumb">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>

                <v-row v-if="$store.state.cart.length <= 0">
                    <v-col cols="12" class="text-center mt-16 mb-16">
                        <div class="text-h4 mb-4">No items to checkout</div>
                        <v-btn to="/store" color="primary">
                            Start Shopping
                        </v-btn>
                    </v-col>
                </v-row>
                <v-row v-else-if="!$store.state.loaded_data">
                    <v-col cols="12" md="6"
                           v-for="i in 4"
                           :key="i"
                    >
                        <v-skeleton-loader
                                class="mx-auto"
                                max-height="300"
                                type="card"
                        ></v-skeleton-loader>
                    </v-col>
                </v-row>
                <v-row v-else>
                    <v-col cols="12" sm="7">
                        <v-row>
                            <v-col cols="12">
                                <v-card
                                        :disabled="checkout_loading"
                                        class="pa-3 pa-sm-5 pa-md-5">
                                    <v-card-title>Cart Items</v-card-title>
                                    <v-divider></v-divider>
                                    <v-simple-table
                                            color="transparent"
                                    >
                                        <template v-slot:default>
                                            <tbody>
                                            <tr
                                                    v-for="cart in $store.state.cart"
                                                    :key="cart.id"
                                                    v-if="getProductById(cart.id) !== null"
                                            >
                                                <td>{{ getProductById(cart.id).name }} <span class="ml-1">X{{ cart.qty }}</span></td>
                                                <td>${{ formatPrice((getProductById(cart.id).dis_price > 0 ? getProductById(cart.id).dis_price : getProductById(cart.id).reg_price) * cart.qty) }}</td>
                                            </tr>
                                            </tbody>
                                        </template>
                                    </v-simple-table>
                                </v-card>
                            </v-col>
                            <v-col
                                    id="bill">
                                <v-card
                                        :disabled="checkout_loading" class="pa-3 pa-sm-5 pa-md-5">
                                    <v-card-title>Shipping Details</v-card-title>
                                    <v-form
                                            ref="bill"
                                            lazy-validation
                                            class="px-4"
                                    >
                                        <v-text-field
                                                v-model="bill.name"
                                                label="Full Name"
                                                :rules="validate.bill.name"
                                                required
                                        ></v-text-field>
                                        <v-text-field
                                                v-model="bill.email"
                                                label="E-mail Address"
                                                :rules="validate.bill.email"
                                                required
                                        ></v-text-field>
                                        <v-text-field
                                                v-model="bill.phone"
                                                label="Phone"
                                                :rules="validate.bill.phone"
                                                required
                                                type="tel"
                                        ></v-text-field>
                                        <v-textarea
                                                outlined
                                                v-model="bill.address"
                                                :rules="validate.bill.address"
                                                label="Address"
                                                required
                                                class="mt-5"
                                        >
                                        </v-textarea>
                                        <v-textarea
                                                outlined
                                                v-model="bill.note"
                                                label="Additional notes about your order"
                                                class="mt-3"
                                        >
                                        </v-textarea>
                                        <div
                                                v-if="hasKitchenItem"
                                        >
                                            <v-subheader>How spicy do you want your orders on a scale of 1-10?</v-subheader>
                                            <v-slider
                                                    max="10"
                                                    min="1"
                                                    v-model="bill.spice"
                                                    step="1"
                                                    thumb-label="always"
                                                    :thumb-size="20"
                                                    class="mt-2"
                                            ></v-slider>
                                        </div>

                                        <v-switch
                                                v-model="save_info"
                                                inset
                                                label="Save information for future use"
                                        ></v-switch>
                                    </v-form>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                    <v-col cols="12" sm="5">
                        <v-row>
                            <v-col cols="12">
                                <v-card
                                        :disabled="checkout_loading"
                                        class="pa-3 pa-sm-5 pa-md-5">
                                    <v-card-title>Use Coupon or Voucher</v-card-title>
                                    <v-form
                                            ref="coupon"
                                            lazy-validation
                                            class="px-4"
                                    >
                                        <v-text-field
                                                v-model="coupon.name"
                                                outlined
                                                clearable
                                                dense
                                                label="Code"
                                                :disabled="coupon.amount > 0"
                                        ></v-text-field>
                                        <div class="text-end mb-3">
                                            <v-btn
                                                    rounded
                                                    color="primary"
                                                    dark
                                                    small
                                                    @click="applyCoupon()"
                                                    :loading="coupon.loading"
                                                    v-if="coupon.amount <= 0"
                                            >
                                                Apply
                                            </v-btn>
                                            <v-btn
                                                    rounded
                                                    color="red"
                                                    dark
                                                    small
                                                    v-else
                                                    @click="removeCoupon()"
                                            >
                                                Remove
                                            </v-btn>
                                        </div>
                                    </v-form>
                                </v-card>
                            </v-col>
                            <v-col cols="12"
                                   id="ship">
                                <v-card
                                        :disabled="checkout_loading"
                                        class="pa-3 pa-sm-5 pa-md-5">
                                    <v-card-title>Estimate Shipping</v-card-title>
                                    <v-form
                                            ref="delivery"
                                            lazy-validation
                                            class="px-4"
                                    >
                                        <v-radio-group
                                                class="mt-0 pt-0"
                                                v-model="delivery_type"
                                        >
                                            <v-radio
                                                    label="Pick Up Station"
                                                    value="pickup"
                                            ></v-radio>
                                            <v-radio
                                                    label="Door delivery"
                                                    value="delivery"
                                            ></v-radio>
                                        </v-radio-group>
                                    </v-form>
                                    <v-form
                                            ref="ship"
                                            lazy-validation
                                            class="px-4"
                                    >
                                        <v-card-subtitle class="mt-0 pt-0 ml-0 pl-0">
                                            Select {{ delivery_type === 'pickup' ? 'a Pick Up Station' : 'your location' }}

                                        </v-card-subtitle>
                                        <v-select
                                                :items="loadLocation"
                                                outlined
                                                v-model="bill.ship_id"
                                                :rules="validate.bill.ship"
                                                @change="setShippingFee()"
                                        ></v-select>
                                    </v-form>
                                </v-card>
                            </v-col>
                            <v-col cols="12" id="checkout">
                                <v-card class="pa-3 pa-sm-5 pa-md-5">
                                    <v-card-title class="mb-0 pb-0">Subtotal: <span class="ml-auto">${{ formatPrice(getCartTotalPrice()) }}</span></v-card-title>
                                    <v-card-title class="mb-0 pb-0" v-if="coupon.amount > 0">Discount: <span class="ml-auto">-${{ formatPrice(coupon.amount) }}</span></v-card-title>
                                    <v-card-title class="mb-0 pb-0">Shipping: <span class="ml-auto">${{ formatPrice(ship_fee) }}</span></v-card-title>
                                    <v-card-title>Total: <span class="ml-auto">${{ formatPrice(getCheckoutTotal()) }}</span></v-card-title>
                                    <v-form
                                        ref="agree"
                                        lazy-validation
                                        class="px-4"
                                    >
                                        <v-checkbox
                                                v-model="agree"
                                                :rules="validate.agree"
                                        >
                                            <template v-slot:label>
                                                <div>
                                                    By clicking this box, you agree to
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <a
                                                                    target="_blank"
                                                                    :href="$store.state.base_url+'terms-of-use'"
                                                                    @click.stop
                                                                    v-on="on"
                                                            >
                                                                our terms of use
                                                            </a>
                                                        </template>
                                                        Opens in new window
                                                    </v-tooltip>
                                                    and
                                                    <v-tooltip top>
                                                        <template v-slot:activator="{ on }">
                                                            <a
                                                                    target="_blank"
                                                                    :href="$store.state.base_url+'privacy-policy'"
                                                                    @click.stop
                                                                    v-on="on"
                                                            >
                                                                privacy policy
                                                            </a>
                                                        </template>
                                                        Opens in new window
                                                    </v-tooltip>.
                                                </div>
                                            </template>
                                        </v-checkbox>
                                    </v-form>
                                    <div class="text-end mb-3 mt-3">
                                        <v-btn
                                            rounded
                                            color="primary"
                                            dark
                                            @click="checkOut()"
                                            :loading="checkout_loading"
                                        >
                                            Checkout
                                        </v-btn>
                                    </div>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
        <v-snackbar
                v-model="toast.show"
                :multi-line="true"
                timeout="5000"
                right
                :color="toast.color"
        >
            {{ toast.text }}
        </v-snackbar>
    </v-container>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Checkout",
        watch: {
            '$store.state.loaded_data'(val){
                if(val){
                    this.setSavedData();
                }
            }
        },
        data(){
            return {
                breadcrumb: [
                    {
                        text: 'Store',
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Cart',
                        disabled: false,
                        to: '/cart',
                    },
                    {
                        text: 'Checkout',
                        disabled: true
                    }
                ],
                bill: {
                    name: '',
                    email: '',
                    phone: '',
                    address: '',
                    note: '',
                    spice: 5,
                    ship_id: ''
                },
                validate: {
                    bill: {
                        name: [
                            v => !!v.trim() || 'Full Name is required'
                        ],
                        email: [
                            v => !!v.trim() || 'Email Address is required',
                            v => /.+@.+\..+/.test(v) || 'Email Address must be valid',
                        ],
                        phone: [
                            v => !!v.trim() || 'Phone is required',
                            v => /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/.test(v) || 'Phone is not valid'
                        ],
                        address: [
                            v => !!v.trim() || 'Address is required'
                        ],
                        ship: [
                            v => !!v || 'Select a valid delivery location'
                        ]
                    },
                    agree: [
                        v => v || 'Please agree to continue'
                    ]
                },
                coupon: {
                    name: '',
                    loading: false,
                    amount: 0
                },
                delivery_type: 'pickup',
                ship_fee: 0,
                coupon_loading: false,
                save_info: false,
                checkout_loading: false,
                agree: false
            }
        },
        metaInfo(){
            return {
                title: 'Checkout'
            }
        },
        created(){
            if(this.$store.state.loaded_data){
                this.setSavedData();
            }
        },
        computed: {
            loadLocation(){
                return this.$store.state.shipping
                    .filter(p => p.type === this.delivery_type )
                    .map(p => {
                        return {
                            text: p.name,
                            value: p.id
                        }
                    });
            },
            hasKitchenItem(){
                let h = false;
                const kc = this.getChildrenCategoryById(9).map(q => {
                    return q.id;
                });
                this.$store.state.cart.forEach(c => {
                    const p = this.getProductById(c.id);
                    if(p !== null){
                        const cats = p.category;
                        for(let i = 0; i < cats.length; i++){
                            if(kc.includes(cats[i].toString())){
                                h = true;
                                break;
                            }
                        }
                    }
                });
                if(!h){
                    this.bill.spice = 1;
                }
                return h;
            }
        },
        methods: {
            applyCoupon(){
                this.coupon.loading = true;
                this.validateCoupon(this.coupon.name)
                    .then(res => {
                    this.coupon.loading = false;
                    if(res.status !== '200'){
                        this.setToast(res.message,'red');
                        this.coupon.amount = 0;
                        return;
                    }
                    if(res.type === 'percent'){
                        this.coupon.amount = this.getCartTotalPrice() * (res.dis/100);
                    }else{
                        this.coupon.amount = res.dis;
                    }
                    this.setToast(res.message,'primary');
                });
            },
            removeCoupon(){
                this.coupon.name = '';
                this.coupon.amount = 0;
            },
            getCheckoutTotal(){
                let p = this.getCartTotalPrice();
                p -= +this.coupon.amount;
                p += +this.ship_fee;
                return p;
            },
            setShippingFee(){
                this.ship_fee = 0;
                let s = this.getShippingById(this.bill.ship_id);
                if(s !== null){
                    this.ship_fee = s.price;
                }
            },
            setSavedData(){
                if(this.$session.has('bill')){
                    this.bill = JSON.parse(this.$session.get('bill'));
                    //this.bill.ship_id = '';
                    const ship = this.getShippingById(this.bill.ship_id);
                    if(typeof ship.type !== 'undefined'){
                        this.delivery_type = ship.type;
                        this.ship_fee = ship.price;
                    }else{
                        this.bill.ship_id = '';
                    }
                }
            },
            checkOut(){
                if(!this.$refs.bill.validate()){
                    document.querySelector('#bill').scrollIntoView();
                    return;
                }
                if(!this.$refs.ship.validate()){
                    document.querySelector('#ship').scrollIntoView();
                    return;
                }
                if(!this.$refs.agree.validate()){
                    document.querySelector('#checkout').scrollIntoView();
                    return;
                }
                this.checkout_loading = true;
                if(this.save_info){
                    this.$session.set('bill',JSON.stringify(this.bill));
                }
                axios.post(this.$store.state.base_url+'api',{
                    action: 'checkout-cart',
                    n: this.bill.name,
                    e: this.bill.email,
                    p: this.bill.phone,
                    a: this.bill.address,
                    s: this.bill.spice,
                    i: JSON.stringify(this.$store.state.cart),
                    c: this.coupon.name,
                    l: this.bill.ship_id,
                    no: this.bill.note
                }, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((res) => {
                    this.checkout_loading = false;
                    if(res.status !== 200){
                        this.setToast('Error creating order','red');
                    }else if(res.data.status !== '200'){
                        this.setToast(res.data.message,'red');
                    }else{
                        this.clearCart();
                        this.$router.push('/invoice/'+res.data.invoice);
                    }
                }, (error) => {
                    this.setToast('Error creating order','red');
                });
            }
        }
    }
</script>

<style scoped>

</style>