import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import About from '../views/About.vue'
import Contact from '../views/Contact.vue'
import StoreCategory from '../views/Store/Category.vue'
import Cart from '../views/Cart'
import Checkout from '../views/Checkout'
import NotFound from '../views/NotFound'
import Invoice from '../views/Invoice'
import Terms from '../views/Terms'
import PrivacyPolicy from '../views/PrivacyPolicy'

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/about-us',
    name: 'About',
    component: About //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/contact-us',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/store',
    name: 'Store',
    component: Home,
  },
  {
    path: '/store/category/:id',
    name: 'StoreCategory',
    component: StoreCategory,
  },
  {
    path: '/cart',
    name: 'Cart',
    component: Cart,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    component: Checkout,
  },
  {
    path: '/invoice/:id',
    name: 'Invoice',
    component: Invoice
  },
  {
    path: '/terms-of-use',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: PrivacyPolicy
  },
  {
    path: '*',
    component: NotFound
  }
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior(to, from, savedPosition){
    if(savedPosition){
      return savedPosition
    }else if(to.hash) {
      return {
        el: to.hash,
        behavior: 'smooth',
      }
    }else{
      return {x :0 , y: 0}
    }
  }
});

export default router
