<template>
    <v-footer
            :color="$vuetify.theme.dark?'dark':'white'"
            padless
            class="mfoot"
    >
        <v-row
                justify="center"
                no-gutters
                class="pa-3"
        >
            <v-col cols="12" md="6" class="text-center text-md-start">
                <v-btn
                        v-for="item in links"
                        :key="item.title"
                        plain
                        :to="item.link"
                        class="bth"
                        style="font-size: 10px;"
                >
                    {{ item.title }}
                </v-btn>
            </v-col>
            <v-col
                    cols="12" md="6" class="text-center text-md-end"
            >
                <v-btn
                        v-for="icon in $store.state.social"
                        :key="icon.name"
                        class="mx-2"
                        icon
                        :href="icon.link"
                        target="_blank"
                >
                    <v-icon size="24px">
                        mdi-{{ icon.name }}
                    </v-icon>
                </v-btn>
            </v-col>
        </v-row>
        <v-fab-transition>
            <v-btn
                    v-show="!show_to_top"
                    color="primary"
                    dark
                    fixed
                    bottom
                    right
                    fab
                    class="mb-3 mr-3"
            >
                <v-icon>mdi-chevron-up</v-icon>
            </v-btn>
        </v-fab-transition>
    </v-footer>
</template>

<script>
    export default {
        name: "Footer",
        data(){
            return {
                show_to_top: true,
                links: [
                    {
                        'title':'Store',
                        'link':'/store',
                    },
                    {
                        'title':'Terms of Use',
                        'link':'/terms-of-use',
                    },
                    {
                        'title':'Privacy Policy',
                        'link':'/privacy-policy',
                    },
                ]
            }
        },
    }
</script>

<style scoped>
    input, optgroup, select, textarea, p, label, a {
        font-size: 12px !important;
        font-weight: 400 !important;
    }
    .bth{
        height: 36px !important;
    }
    @media (max-width: 767px) {
        input, optgroup, select, textarea, p, label, a {
            font-size: 10px !important;
        }
        .bth{
            height: 25px !important;
        }
    }
</style>