<template>
    <v-container
            class="px-3 px-md-4"
    >
        <v-row dense
          class="px-1 px-sm-0"
      >
        <v-col
                cols="12"
        >
            <div v-if="!$store.state.loaded_data">
                <v-skeleton-loader
                        class="mt-6 mx-auto"
                        height="200"
                        type="card"
                ></v-skeleton-loader>
            </div>
            <div v-else>
                <Carousel
                        :autoplay="$store.state.banners.length > 1"
                        :loop="$store.state.banners.length > 1"
                        :nav="false"
                        :items="1"
                        class="mt-6 mb-2"
                >
                    <div
                            v-for="(item, n) in $store.state.banners"
                            v-if="item.type === 'slider'"
                            :key="item.id"
                            class="px-2"
                    >
                        <v-card
                                style="width: 100%;"
                                :href="item.link"
                        >
                            <v-img
                                    :src="item.image"
                                    class="banner-height"
                            >
                            </v-img>
                        </v-card>
                    </div>
                </Carousel>
            </div>

        </v-col>
      </v-row>
        <v-row>
            <v-col cols="12">
                <h3 class="pb-3 ml-3" style="font-weight: 500;">Store Categories</h3>
                <div v-if="!$store.state.loaded_data">
                        <v-skeleton-loader
                                class="px-2 mx-auto"
                                height="200"
                                type="card"
                        ></v-skeleton-loader>
                </div>
                <div v-else>
                    <Carousel
                            :autoplay="false"
                            :nav="false"
                            :responsive="{0:{items:1},400:{items:2},768:{items:2},1024:{items:3}}"
                    >
                        <div
                                v-for="(item, n) in $store.state.category"
                                :key="item.id"
                                class="px-2"
                                v-if="item.id !== '9'"
                        >
                            <slide-y-down-transition
                                    appear
                                    :delay="400 + (n * 100)">
                                <v-card
                                        style="width: 100%;"
                                        shaped
                                        :to="'store/category/'+item.slug"
                                >
                                    <v-img
                                            :src="item.image"
                                            class="item-height"
                                            :gradient="$vuetify.theme.dark?'to top, rgba(0,0,0,0), rgba(0,0,0,1)':'to top, rgba(255,255,255,0), rgba(255,255,255,1)'"
                                    >
                                        <v-list-item class="">
                                            <v-list-item-title
                                                    v-text="item.name" class=""></v-list-item-title>
                                            <v-list-item-icon>
                                                <v-btn
                                                    icon
                                                >
                                                    <v-icon
                                                            fab
                                                    >
                                                        mdi-arrow-right
                                                    </v-icon>
                                                </v-btn>

                                            </v-list-item-icon>
                                        </v-list-item>
                                    </v-img>
                                </v-card>
                            </slide-y-down-transition>
                        </div>
                    </Carousel>
                </div>

            </v-col>
        </v-row>
        <v-row v-if="$store.state.loaded_data">
            <v-col cols="12"
                   v-for="cat in $store.state.featured_cat"
                   :key="cat"
            >
                <div
                    v-if="getCategoryById(cat) !== null && catProducts(cat).length > 0"
                >
                    <v-row>
                        <v-col cols="6">
                            <h3 class="pb-3 ml-3" style="font-weight: 500;">{{ getCategoryById(cat).name }}</h3>
                        </v-col>
                        <v-col cols="6" class="text-end">
                            <v-btn
                                    plain
                                    color="primary"
                                    :to="'store/category/'+getCategoryById(cat).slug"
                            >
                                See All
                                <v-icon>mdi-chevron-right</v-icon>
                            </v-btn>
                        </v-col>
                    </v-row>
                    <Carousel
                            :autoplay="false"
                            :nav="false"
                            :responsive="{0:{items:1},350:{items:2},768:{items:3},1024:{items:4},1440:{items:6}}"
                    >
                        <div
                                v-for="p in catProducts(cat,'desc','10')"
                                :key="p.id"
                                class="px-2 mb-2"
                        >
                            <ProductItem
                                :item="p"
                                max-width="374"
                                height="320"
                            >
                            </ProductItem>
                        </div>
                    </Carousel>
                </div>

            </v-col>
        </v-row>
    </v-container>
</template>

<script>
// @ is an alias to /src
import Carousel from 'vue-owl-carousel'
import VueCarousel from 'vue-owl-carousel'
import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
import ProductItem from '@/components/ProductItem';
export default {
    name: 'Home',
    metaInfo(){
        return {
            title: 'Welcome'
        }
    },
    watch: {
        '$store.state.loaded_data'(val){
            if(val){

            }
        }
    },
    created(){
        this.banner = this.$store.state.banners;
        //console.log(this.catProducts(1));
    },
      data(){
        return {

        }
      },
    methods: {

    },

      components: {
        Carousel ,
          VueCarousel,
        SlideYUpTransition,
        SlideYDownTransition,
          ProductItem
      }
}
</script>
<style scoped>
    .item-height{
        height: 150px;
    }
    .banner-height{
        max-height: 400px;
    }
    @media (max-width: 425px){
        .item-height{
            height: 100px;
        }
    }
</style>
