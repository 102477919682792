import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
    breakpoint: {
        scrollBarWidth: 16,
        thresholds: {
            xs: 375,
            sm: 576,
            md: 768,
            lg: 992,
            xl: 1200
        },
    },
    theme: {
        themes: {
            light: {
                primary: '#ad7228'
            },
            dark: {
                primary: '#ad7228'
            },
        },
    },
});
