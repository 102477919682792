<template>
  <v-app>
    <Header/>
    <v-main>
      <slide-x-left-transition appear mode="out-in">
        <router-view :key="$route.fullPath"></router-view>
      </slide-x-left-transition>
      <v-snackbar
              v-model="$store.state.addedtocart"
              :multi-line="true"
              timeout="3000"
              right
              color="primary"
      >
        You added {{ $store.state.addedtocartitem }} to cart!

        <template v-slot:action="{ attrs }">
          <v-btn
                  color="white"
                  text
                  v-bind="attrs"
                  @click="$store.commit('addedToCart',{status: false, item: ''})"
                  to="/checkout"
          >
            Checkout
          </v-btn>
        </template>
      </v-snackbar>
    </v-main>
    <Footer/>
  </v-app>
</template>

<script>
import Header from '@/components/Shared/Header.vue'
import Footer from '@/components/Shared/Footer.vue'
import { SlideXLeftTransition } from 'vue2-transitions';
import { mapActions } from 'vuex';
export default {
  name: 'App',
  watch: {
    '$vuetify.theme.dark'(val) {
      let metaThemeColor = document.querySelector("meta[name=theme-color]");
      setTimeout(()=>{
        if(val){
          this.metaThemeColor = '#000000';
          metaThemeColor.setAttribute("content", this.metaThemeColor);
        }else{
          this.metaThemeColor = '#ffffff';
          metaThemeColor.setAttribute("content", this.metaThemeColor);
        }
        if(val && this.$session.has('theme')){
          this.$session.set('theme','dark');
        }
        if(!val && !this.$session.has('theme')){
          this.$session.set('theme','light');
        }
      },1000);

    }
  },
  created(){
        const session = this.$session;
        const store = this.$store;
        if(!session.has('theme')){
            const darkMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
            if (darkMediaQuery.matches) {
              this.$vuetify.theme.dark = true;
            }
            try {
              // Chrome & Firefox
              darkMediaQuery.addEventListener('change', (e) => {
                  this.$vuetify.theme.dark = e.matches;
              });
            } catch (e1) {
              try {
                // Safari
                darkMediaQuery.addListener((e) => {
                    this.$vuetify.theme.dark = e.matches;
                });
              } catch (e2) {
                console.error(e2);
              }
            }
        }else{
          this.$vuetify.theme.dark = session.get('theme') === 'dark';
        }
        this.loadData();

        if(!session.has('cart') || typeof session.get('cart') === 'undefined' || !Array.isArray(JSON.parse(session.get('cart')))){
          this.setCart([]);
        }else{
          store.commit('setCart', JSON.parse(session.get('cart')));
        }
  },
  data: () => ({
      'metaThemeColor': '#ffffff',
  }),
  methods: {
    ...mapActions([
          "loadData"
    ]),
  },
  metaInfo(){
    return {
      title: 'Welcome',//this.$store.state.title,
      titleTemplate: '%s | Exclusive Fingers',
      link: [
        { rel: 'shortcut icon', href: 'exclusive-fingers-favicon-new.png' },
        { rel: 'icon', href: 'exclusive-fingers-favicon-new.png' },
      ],
      meta: [
        //{name: 'theme-color', content: this.metaThemeColor}
      ]
    }
  },
  components: {
    Header,
    Footer,
    SlideXLeftTransition
  }
};
</script>
<style>
  input, optgroup, select, textarea, p, label, a{
    font-size: 14px !important;
    font-weight: 500 !important;
  }
  @media (min-width: 1024px){
    input, optgroup, select, textarea, p, label, a{
      font-size: 15px !important;
    }
  }
  a{
    text-decoration: none;
  }
</style>
