<template>
    <v-row>
        <v-col cols="12" class="text-center">
            <v-card
                max-width="500"
                class="mx-auto"
                color="transparent"
                flat
            >
                <v-img
                        :src="require('@/assets/404.svg')"
                />
                <p class="text-h6 text-md-h5 text-lg-h4">
                    {{ msg }}
                </p>
            </v-card>
        </v-col>
    </v-row>
</template>

<script>
    export default {
        name: "NotFound",
        props: {
            msg: {
                type: String,
                default: 'The page you are looking for might have been removed, had name changed, or is temporarily unavailable.'
            }
        }
    }
</script>

<style scoped>

</style>