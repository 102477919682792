<template>
    <div>
        <v-app-bar
                app
                elevate-on-scroll
                height="70"
                class="px-md-4"
                :color="$vuetify.theme.dark?'dark':'white'"
        >
            <div class="d-flex align-center">
                <router-link to="/">
                    <v-img
                            alt="Vuetify Logo"
                            class="shrink mr-2"
                            contain
                            :src="$vuetify.theme.dark?require('@/assets/exclusive-fingers-logo-new-white.png'):require('@/assets/exclusive-fingers-logo-new.png')"
                            transition="scale-transition"
                            width="150"
                    />
                </router-link>
            </div>
            <v-spacer></v-spacer>
            <div class="hidden-sm-and-down mr-5">
                <v-menu
                        open-on-hover
                        bottom
                        offset-y
                        v-for="item in menus"
                        :key="item.id"
                        transition="slide-y-transition"
                >
                    <template v-slot:activator="{ on, attrs }">
                        <v-btn
                                plain
                                v-bind="attrs"
                                v-on="on"
                                :to="item.link"
                        >
                            <span class="mr-2" v-text="item.title"></span>
                            <v-icon v-if="item.items" class="ml-n2">mdi-chevron-down</v-icon>
                        </v-btn>
                    </template>
                    <div v-if="item.items">
                        <v-list>
                            <v-list-item
                                    v-for="child in item.items"
                                    :key="child.id"
                                    link
                                    :to="child.link"
                            >
                                <v-list-item-title>{{ child.title }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </div>
                </v-menu>
            </div>
            <router-link to="/cart">
                <v-btn
                        icon
                >
                    <v-icon>mdi-cart</v-icon>
                    <v-badge
                            overlap
                            :content="$store.state.cart.length"
                            :value="$store.state.cart.length"
                    ></v-badge>
                </v-btn>
            </router-link>

            <v-btn
                    class="hidden-sm-and-down ml-5"
                    icon
                    @click="toggleTheme"
            >
                <v-icon>{{ $vuetify.theme.dark?'mdi-white-balance-sunny':'mdi-moon-waning-crescent' }}</v-icon>
            </v-btn>
            <v-app-bar-nav-icon
                    class="hidden-md-and-up"
                    @click.stop="drawer = !drawer">

            </v-app-bar-nav-icon>
        </v-app-bar>
        <v-navigation-drawer
                v-model="drawer"
                fixed
                right
                temporary
        >
            <v-list-item>
                <v-list-item-title class="text-h6">
                    Explore
                </v-list-item-title>
                <v-list-item-icon>
                    <v-icon
                            @click="toggleTheme">
                        {{ $vuetify.theme.dark?'mdi-white-balance-sunny':'mdi-moon-waning-crescent' }}
                    </v-icon>
                </v-list-item-icon>
            </v-list-item>

            <v-divider></v-divider>
            <v-list
                nav
                dense
            >
                <div
                     v-for="item in menus"
                     :key="item.id"
                     >
                    <div
                            v-if="item.items"
                    >
                        <v-list-group
                                no-action
                        >
                            <template v-slot:activator>
                                <v-list-item-content>
                                    <v-list-item-title v-text="item.title"></v-list-item-title>
                                </v-list-item-content>
                            </template>
                            <div v-for="child in item.items"
                                 :key="child.id">
                                <div v-if="child.items">
                                    <v-list-group
                                            no-action
                                            sub-group
                                    >
                                        <template v-slot:activator>
                                            <v-list-item-content>
                                                <v-list-item-title v-text="child.title"></v-list-item-title>
                                            </v-list-item-content>
                                        </template>

                                        <v-list-item
                                                v-for="sub in child.items"
                                                :key="sub.id"
                                                link
                                                :to="sub.link"
                                        >
                                            <v-list-item-title v-text="sub.title"></v-list-item-title>
                                        </v-list-item>
                                    </v-list-group>
                                </div>
                                <div v-else>
                                    <v-list-item
                                            link
                                            class="pl-7"
                                            :to="child.link"
                                    >
                                        <v-list-item-content>
                                            <v-list-item-title v-text="child.title"></v-list-item-title>
                                        </v-list-item-content>
                                    </v-list-item>
                                </div>
                            </div>
                        </v-list-group>
                    </div>
                    <div
                            v-else
                    >
                        <v-list-item
                                link
                                :to="item.link"
                        >
                            <v-list-item-content>
                                <v-list-item-title v-text="item.title"></v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </div>
                </div>

            </v-list>
            <v-spacer></v-spacer>

            <template v-slot:append>
                <div class="pa-2 text-right">
                    <v-btn
                            v-for="icon in $store.state.social"
                            :key="icon.name"
                            icon
                            :href="icon.link"
                            target="_blank"
                    >
                        <v-icon size="24px">
                            mdi-{{ icon.name }}
                        </v-icon>
                    </v-btn>
                </div>
            </template>
        </v-navigation-drawer>
    </div>
</template>

<script>
    export default {
        name: "Header",
        data(){
            return {
                drawer: false,
                menus:  [
                    {
                        items: [
                            {
                                id: '1',
                                link: '/store/category/kitchen',
                                title: 'Kitchen'
                            },
                            {
                                id: '2',
                                link: '/store/category/glaze-it-lip',
                                title: 'Glaze it Lip Gloss',
                            },
                            {
                                id: '3',
                                link: '/store/category/wears',
                                title: 'Wears',
                            },
                        ],
                        id: '4',
                        title:  'Store',
                        link: '/store'
                    },
                    {
                        id: '5',
                        title:  'About Us',
                        link: '/about-us',
                    },
                    {
                        id: '6',
                        title:  'Contact Us',
                        link: '/contact-us'
                    },
                ],
            }
        },
        created(){

        },
        methods: {
            gotoCart(){
                if(this.$router.currentRoute.path !== '/cart'){
                    this.$router.push('/cart');
                }
            },
            toggleTheme(){
                this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
                this.$session.set('theme',this.$vuetify.theme.dark?'dark':'light');
            }
        }
    }
</script>

<style scoped>

</style>