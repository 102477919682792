<template>
    <v-container
    >
        <v-row class="py-5 py-md-16 px-3 px-md-0">
            <v-col cols="12" class="pb-md-10 pll-6 pll-md-12">
                <h2>Contact Us</h2>
            </v-col>
            <v-col cols="12">
                <v-row>
                    <v-col cols="12" md="5"
                           class="px-5 px-sm-8 order-1 order-md-0"
                    >
                        <v-card class="px-4 pb-8">
                            <v-card-title class="head">
                                Speed dial
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-row class="py-5 pl-4">
                                <v-col cols="4" md="3" class="align-self-center text-end">
                                    <v-btn fab
                                           color="primary"
                                           dark
                                           href="tel:+1(224) 388-1978"
                                           target="_blank"
                                           outlined
                                    >
                                        <v-icon
                                        >mdi-phone</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="8" md="9" class="align-self-center">
                                    <a
                                            href="tel:+1(224) 388-1978"
                                            target="_blank"
                                            class="text--primary"
                                            
                                    >
                                        +1(224) 388-1978
                                    </a>
                                    <v-divider class="mt-5"></v-divider>
                                </v-col>
                                <v-col cols="4" md="3" class="align-self-center text-end">
                                    <v-btn fab
                                           color="primary"
                                           dark
                                           href="mailto:exclusivefingers@gmail.com"
                                           target="_blank"
                                           outlined
                                    >
                                        <v-icon
                                        >mdi-gmail</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="8" md="9" class="align-self-center">
                                    <a
                                            href="mailto:exclusivefingers@gmail.com"
                                            target="_blank"
                                            class="text--primary"
                                            
                                    >
                                        exclusivefingers@gmail.com
                                    </a>
                                    <v-divider class="mt-5"></v-divider>
                                </v-col>
                                <v-col cols="4" md="3" class="align-self-center text-end">
                                    <v-btn fab
                                           color="primary"
                                           dark
                                           outlined
                                    >
                                        <v-icon
                                        >mdi-instagram</v-icon>
                                    </v-btn>
                                </v-col>
                                <v-col cols="8" md="9" class="align-self-center">
                                    <a
                                            href="https://instagram.com/exclusive_fingers"
                                            target="_blank"
                                            class="text--primary"
                                            
                                    >
                                        Exclusive Fingers
                                    </a>
                                    <br/>
                                    <a
                                            href="https://instagram.com/_glazeit"
                                            target="_blank"
                                            class="text--primary"
                                            
                                    >
                                        Glaze It
                                    </a>
                                    <v-divider class="mt-5"></v-divider>
                                </v-col>
                            </v-row>
                        </v-card>
                    </v-col>
                    <v-col cols="12" md="7"
                           class="px-5 px-sm-8 order-0 order-md-1"
                           id="form"
                    >
                        <v-card class="px-4 pb-8"
                                :disabled="sending"
                        >
                            <v-card-title class="head">
                                Get in touch
                            </v-card-title>
                            <v-divider></v-divider>
                            <v-card-text class="my-0 py-0 pt-3">
                                <p>Need to make enquiries, book appointment, report a case? Drop us a message now</p>
                            </v-card-text>
                            <v-form
                                    ref="form"
                                    lazy-validation
                                    class="px-4"
                            >
                                <v-text-field
                                        v-model="name"
                                        label="Full Name"
                                        :rules="validate.name"
                                        required
                                ></v-text-field>
                                <v-text-field
                                        v-model="email"
                                        label="E-mail Address"
                                        :rules="validate.email"
                                        required
                                ></v-text-field>
                                <v-text-field
                                        v-model="subject"
                                        label="Subject"
                                        :counter="100"
                                        required
                                        :rules="validate.subject"
                                ></v-text-field>
                                <v-textarea
                                        outlined
                                        v-model="message"
                                        label="Your Message"
                                        :rules="validate.message"
                                        required
                                        class="mt-8"
                                >

                                </v-textarea>
                                <div class="text-end">
                                    <v-btn
                                            rounded
                                            color="primary"
                                            dark
                                            :loading="sending"
                                            @click="sendMessage()"
                                    >
                                        Send
                                    </v-btn>
                                </div>
                            </v-form>
                        </v-card>
                    </v-col>
                </v-row>
            </v-col>

        </v-row>
        <v-snackbar
                v-model="toast.show"
                :multi-line="true"
                timeout="5000"
                right
                :color="toast.color"
        >
            {{ toast.text }}
        </v-snackbar>
    </v-container>
</template>

<script>
    import axios from 'axios';
    export default {
        name: "Contact",
        data(){
            return {
                name: '',
                email: '',
                subject: '',
                message: '',
                validate: {
                    name: [
                        v => !!v.trim() || 'Full Name is required'
                    ],
                    email: [
                        v => !!v.trim() || 'Email Address is required',
                        v => /.+@.+\..+/.test(v) || 'Email Address must be valid',
                    ],
                    subject: [
                        v => !!v.trim() || 'Subject cannot be empty',
                        v => v.length < 100 || 'Maximum number of character is 100'
                    ],
                    message: [
                        v => !!v.trim() || 'Please enter your message'
                    ],
                },
                sending: false
            }
        },
        methods: {
            sendMessage(){
                if(!this.$refs.form.validate()){
                    return document.querySelector('#form').scrollIntoView();
                }
                this.sending = true;
                axios.post(this.$store.state.base_url+'api',{
                    action: 'contact-us',
                    n: this.name,
                    e: this.email,
                    s: this.subject,
                    c: this.message
                }, {
                    headers: {
                        "Content-Type": "multipart/form-data"
                    }
                }).then((res) => {
                    this.sending = false;
                    if(res.status !== 200){
                        this.setToast('Error sending message','red');
                    }else if(res.data.status !== '200'){
                        this.setToast(res.data.message,'red');
                    }else{
                        this.setToast(res.data.message,'primary');
                        this.name = '';
                        this.email = '';
                        this.subject = '';
                        this.message = '';
                        this.$refs.form.resetValidation();
                    }
                }, (error) => {
                    this.setToast('Error sending message','red');
                });
            }
        }
    }
</script>

<style scoped>

</style>