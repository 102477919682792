<template>
    <v-container>
        <v-row class="py-5 py-md-16 px-3 px-md-0">
            <v-col cols="12" class="pb-md-10 pll-6 pll-md-12">
                <h2>Cart</h2>
                <v-breadcrumbs class="pa-0 ma-0 mt-2 mt-md-3 mb-10" :items="breadcrumb">
                    <template v-slot:divider>
                        <v-icon>mdi-chevron-right</v-icon>
                    </template>
                </v-breadcrumbs>
                <v-row
                    v-if="$store.state.cart.length > 0"
                >
                    <v-col cols="12">
                        <v-row v-if="$store.state.loaded_data">
                            <v-col
                                    cols="12"
                                    sm="6"
                                    v-for="(cart,n) in $store.state.cart"
                                    :key="cart.id"
                                    v-if="getProductById(cart.id) !== null">
                                <slide-y-down-transition
                                        appear
                                        :delay="400 + (n * 100)">
                                    <v-card
                                            class="mx-auto mb-4 mb-md-8"
                                            outlined
                                    >
                                        <v-list-item
                                                class="mb-0 pb-0"
                                        >
                                            <v-list-item-avatar
                                                    tile
                                                    size="80"
                                                    color="grey"
                                            >
                                                <v-img
                                                        :src="getProductById(cart.id).image"
                                                ></v-img>
                                            </v-list-item-avatar>
                                            <v-list-item-content
                                                    class="mb-0 pb-0"
                                            >
                                                <div class="text-overline">
                                                    {{ getProductById(cart.id).name }}
                                                </div>
                                                <v-list-item-title class="text-h6 mb-1">
                                                    ${{ getProductById(cart.id).dis_price > 0 ? formatPrice(getProductById(cart.id).dis_price) : formatPrice(getProductById(cart.id).reg_price) }}
                                                </v-list-item-title>
                                                <v-text-field
                                                        solo
                                                        flat
                                                        prepend-inner-icon="mdi-minus"
                                                        append-icon="mdi-plus"
                                                        style="max-width: 150px; max-height: 50px;"
                                                        type="number"
                                                        hide-details="true"
                                                        class="item--qty"
                                                        :value="cart.qty"
                                                        @change="cartInputAction(cart.id,'update',$event)"
                                                        @click:append="cartInputAction(cart.id,'add')"
                                                        @click:prepend-inner="cartInputAction(cart.id,'minus')"
                                                ></v-text-field>
                                            </v-list-item-content>
                                        </v-list-item>
                                        <v-card-actions
                                                class="justify-end mt-0 pt-0"
                                        >
                                            <v-btn
                                                    color="primary"
                                                    text
                                                    @click="removeCartItem(cart.id)"
                                            >
                                                Remove
                                            </v-btn>
                                        </v-card-actions>
                                    </v-card>
                                </slide-y-down-transition>
                            </v-col>
                            <v-col cols="12" >
                                <v-card
                                        class="pa-4"
                                >
                                    <v-row>
                                        <v-col class="pl-6" cols="12" md="6">
                                            <div class="text-h6">Subtotal: ${{ formatPrice(getCartTotalPrice()) }}</div>
                                        </v-col>
                                        <v-col
                                                class="text-end"
                                                cols="12" md="6">
                                            <v-btn
                                                    plain
                                                    color="primary"
                                                    @click="clearCart"
                                            >
                                                Clear Cart
                                            </v-btn>
                                            <v-btn
                                                    plain
                                                    color="primary"
                                                    to="/checkout"
                                            >
                                                Checkout
                                            </v-btn>
                                        </v-col>
                                    </v-row>
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row v-else>
                            <v-col cols="12" md="6"
                                   v-for="i in 12"
                                   :key="i"
                            >
                                <v-skeleton-loader
                                        class="mx-auto"
                                        max-height="200"
                                        type="card"
                                ></v-skeleton-loader>
                            </v-col>
                        </v-row>
                    </v-col>

                </v-row>
                <v-row v-else>
                    <v-col cols="12" class="text-center mt-16 mb-16">
                        <div class="text-h4 mb-4">No items in cart</div>
                        <v-btn to="/store" color="primary">
                            Start Shopping
                        </v-btn>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
    import { SlideYUpTransition, SlideYDownTransition } from 'vue2-transitions';
    export default {
        name: "Cart",
        data(){
            return {
                breadcrumb: [
                    {
                        text: 'Store',
                        disabled: false,
                        to: '/',
                    },
                    {
                        text: 'Cart',
                        disabled: true,
                    }
                ]
            }
        },
        metaInfo(){
            return {
                title: 'Cart'
            }
        },
        created(){

        },
        methods: {

        },
        components: {
            SlideYUpTransition,
            SlideYDownTransition,
        }
    }
</script>

<style>
    .item--qty input{
        text-align: center;
    }
</style>